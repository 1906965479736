import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import SEO from 'src/components/SEO';
import Heading, { HeadingVariant, HeadingScope } from 'src/components/Heading';
import { device } from 'src/theme/breakpoints';
import Layout from 'src/components/Layout';
import { colors } from 'src/theme/colors';
import Content from 'src/components/Content';
import Grid from 'src/components/Grid';

const PageWrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;

  @media ${device.laptopSUP} {
    column-gap: 120px;
    grid-template-columns: 1fr 1fr;
    padding-top: 60px;
    row-gap: 80px;
  }
`;

const MainWrapper = styled.div`
  background-color: ${colors.purple400};
  color: ${colors.white};
  padding: 24px;

  @media ${device.laptopUP} {
    padding: 104px;
  }
`;

const GreyBackground = styled.div`
  background-color: ${colors.grey100};
  padding: 20px 0 60px;

  @media ${device.laptopSUP} {
    margin-top: 100px;
    padding: 100px 0;
  }
`;

const Section = styled.div`
  padding: 20px 0;
`;

const ImagesWrapper = styled.div`
  & > :not(:last-child) {
    margin-bottom: 40px;
  }
`;

const CenteredGrid = styled(Grid)`
  align-items: center;
`;

const CompanyPage: FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Content>
        <MainWrapper>
          <Heading variant={HeadingVariant.BRAVO}>
            <Trans i18nKey="company_main_heading" />
          </Heading>
          <p>
            <Trans i18nKey="company_main_text" />
          </p>
        </MainWrapper>
        <PageWrapper>
          <HeadingScope>
            <Section>
              <Heading variant={HeadingVariant.CHARLIE}>
                <Trans i18nKey="company_first_section_heading" />
              </Heading>
              <p>
                <Trans i18nKey="company_first_section_text" />
              </p>
            </Section>
            <StaticImage
              layout="fullWidth"
              alt={t('company_first_section_image_alt')}
              src="../assets/images/people.webp"
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
            />
            <StaticImage
              layout="fullWidth"
              alt={t('company_second_section_image_alt')}
              src="../assets/images/people2.jpeg"
              formats={['auto', 'webp', 'avif']}
              placeholder="blurred"
            />
            <Section>
              <Heading variant={HeadingVariant.CHARLIE}>
                <Trans i18nKey="company_second_section_heading" />
              </Heading>
              <p>
                <Trans i18nKey="company_second_section_text" />
              </p>
            </Section>
          </HeadingScope>
        </PageWrapper>
      </Content>
      <GreyBackground>
        <HeadingScope>
          <Content>
            <CenteredGrid columns={2}>
              <div>
                <Heading variant={HeadingVariant.CHARLIE}>
                  <Trans i18nKey="company_fourth_section_heading" />
                </Heading>
                <p>
                  <b>
                    <Trans i18nKey="company_third_section_intro" />
                  </b>
                </p>
                <p>
                  <Trans i18nKey="company_fourth_section_text" />
                </p>
              </div>
              <ImagesWrapper>
                <StaticImage
                  layout="fullWidth"
                  alt={t('company_third_section_image_alt')}
                  src="../assets/images/instegral-team2.webp"
                  formats={['auto', 'webp', 'avif']}
                  placeholder="blurred"
                />
                <StaticImage
                  layout="fullWidth"
                  alt={t('company_fourth_section_image_alt')}
                  src="../assets/images/instegral-team.jpeg"
                  formats={['auto', 'webp', 'avif']}
                  placeholder="blurred"
                />
              </ImagesWrapper>
            </CenteredGrid>
          </Content>
        </HeadingScope>
      </GreyBackground>
    </Layout>
  );
};

export default CompanyPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => <SEO title="Company | Instegral" />;
